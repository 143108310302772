// 
// ecommerce.scss
//


// checkout

.checkout-tabs{
    .nav-pills{
        .nav-link{
            margin-bottom: 24px;
            text-align: center;
            background-color: $card-bg;
            box-shadow: $box-shadow;
            padding: 0px;
            border-radius: 8px;
            &.active{
                background: #FFFFFF;
                border: 1px solid #000000;
                border-radius: 8px;
                color: #000;
                padding: 0px 21px;
            }
            .check-nav-icon{
                font-size: 36px;
            }
        }
        .nav-item{
            cursor: pointer;
            margin-right: 24px;
            width: 200px;
        }
    }
}