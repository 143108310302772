//
// _buttons.scss
//
button,
a {
  outline: none !important;
}

// :not(.btn-check) + .btn:hover, .btn:first-child:hover {
//   color: var(--bs-btn-hover-color);
//   background-color: #009fc7;
//   border-color: #009fc7;
// }

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark,
.btn-secondary {
  color: $gray-200 !important;
}

.cms-button {
  margin: 80px 0px 0px 0px !important;
}

.view-button {
  background: transparent;
  border: 1px solid #009fc7;
  color: #009fc7;
}

.view-button:hover {
  background: #009fc7;
  color: #ffffff !important;
  border: 1px solid #009fc7;
}

.deactivate-button {
  background: #db4423;
  border: 1px solid #db4423;
  color: #ffffff;
}

.deactivate-button:hover {
  background: #af361c;
  color: #ffffff;
  border: 1px solid #af361c;
}

.activate-button {
  background: #427f00;
  border: 1px solid #427f00;
  color: #ffffff;
  width: 100%;
}

.activate-button:hover {
  background: #427f00;
  color: #ffffff;
  border: 1px solid #427f00;
}

.complaint-view-btn {
  background: transparent;
  color: #009fc7 !important;
  border: 1px solid #009fc7;
  width: 100%;
}

.complaint-view-btn:hover {
  background: #009fc7;
  color: #ffffff !important;
  border: 1px solid #009fc7;
}

.sort-by-btn {
  width: 100%;
}

.privacy-policy-box {
  width: 100%;
}

.add-lobby-row .verified-check {
  margin-bottom: 0px;
}

.add-lobby-row .verified-check-row .form-label {
  margin-bottom: 0px;
}

.select-state .multiSelectContainer {
  background-image: url("/assets/images/drop-arrow.svg");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: top 50% right 10px;
}

.history-tab .seize-wallet-btn {
  display: block !important;
  margin: 16px 14px 16px auto;
}

.spinner-class {
  position: absolute;
  top: 56%;
  left: 46%;
}

.transaction-spinner {
  position: sticky;
  left: 46%;
}

.video-player {
  margin-top: 20px;
}

.lobby-error {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ff715b;
}

.user-error {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ff715b;
}

.vertical-simplebar .community-image {
  max-width: 20px;
  margin-right: 9px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

// Radhika CSS

.referal-listing {
  margin-left: 0px;
}

.total-referal {
  background: #ffffff;
  border: 1px solid #848484;
  box-sizing: border-box;
  border-radius: 4px;
  width: 212px;
  height: 136px;
  padding: 16px;
  margin-right: 24px;
}

.total-referal h5 {
  font-size: 18px;
  padding-bottom: 16px;
}

.earningTimelines-box .form-group {
  width: 100%;
}

.earningTimelines-box .form-group input {
  border-right: none;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
}

.daysBox {
  margin-top: 38px;
  max-width: 20%;
}

.daysBox span {
  border: 1px solid #ced4da;
  padding: 10px 25px;
  border-radius: 0rem 0.25rem 0.25rem 0rem;
}

.daysBox .manageDeposit {
  padding: 10px 36px;
}

.daysBox .managePromoBonus {
  padding: 10px 37px;
}

.teamName {
  text-align: center;
  vertical-align: middle;
}

input#favourite_friend,
input#max_pinned_demo_count {
  border: 1px solid #ced4da;
}

.dropdown-item.text-danger,
.dropdown-item {
  padding: 0.25rem 0.5rem !important;
}

.preview-img .upload-img img {
  max-width: 140px;
}

.errorMsgGames {
  color: #ff715b;
  display: block;
  // padding-top: 15px;
}
label.errorMsgGames {
  color: #ff715b !important;
  display: block !important;
}
.chooseImage .custom-file-label {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  max-width: 400px;
  text-indent: 30px;
  cursor: pointer;
}

.custom-file-input {
  display: none;
}

.custom-file-label::before {
  position: absolute;
  left: -8px;
  z-index: 3;
  display: block;
  content: url("data: image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15' stroke='%23626C76' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17 8L12 3L7 8' stroke='%23626C76' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 3V15' stroke='%23626C76' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.lobby-upload-btn .custom-file {
  margin-left: 30px;
}

.lobby-upload-btn .custom-file-label::before {
  left: 11px;
}

.info-icon {
  position: relative;
  display: inline-block;
}

.info-icon {
  .tooltiptext {
    visibility: hidden;
    width: 305px;
    background-color: #d8d8d8;
    color: #000;
    text-align: left;
    border-radius: 6px;
    padding: 5px 8px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    font-weight: 400;
    font-size: 12px;

    &.tooltipTournament {
      right: 0px;
      bottom: 24px !important;
      width: 150px !important;
    }

    &.edit-tournament-tooltip {
      width: auto !important;
      bottom: 30px;
      right: -65px;
    }
  }
}

.info-icon:hover .tooltiptext {
  visibility: visible;
}

.stats-infoIcon {
  margin-left: 8px;
}

.dropdown-menu.show {
  width: 100% !important;
}

.closeIcon {
  display: none;
}

.enrolled-players-tab .table-responsive {
  overflow-x: initial;
}

.edit-icon,
.edit-icon:hover {
  border: unset;
  background-color: transparent !important;
}

.editEarning-timeline {
  width: 330px;
  padding-left: 4px;
  .editEarning-timeline {
    button {
      &:hover {
        background-color: unset !important;
      }
      .tooltip {
        right: 0 !important;
      }
    }
  }
}

.editDaysBox {
  max-width: 26%;
}

.imagesUpload {
  border: 1px solid #000;
  width: 130px;
  min-height: 110px;
  border-radius: 4px;
  position: relative;
}

.crossButton,
.crossButton:focus {
  background: transparent !important;
  color: #000 !important;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 55px;
  border: none;
}

.uploadImage {
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
}

.dropzone-previews {
  display: flex;
  flex-flow: row wrap;
  padding-left: 13px;

  .multipleImages {
    margin-right: 20px;
  }
}

.csvUploadTable {
  width: 320px;
  border: 1px solid #000;
}

.previewImage img {
  // min-width: 10px;
  max-height: 400px;
}

.demoCancelbtn {
  margin-right: 30px;
}

.processingFile {
  position: inherit;
  display: table;
  margin-top: 50px;
  width: 170px;
  margin-left: -40px;
}

.processingLoader {
  float: left;
  display: flex;
  justify-content: center;
  border: 1px solid #000;
  width: 180px;
  height: 150px;
  margin-left: 15px;

  .transaction-spinner {
    position: inherit;
    left: unset;
    top: 40px;
  }
}

.previewCrossIcon,
.previewCrossIcon:focus {
  text-align: right;
  left: -4px;
}

.modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 100%;
  max-width: 945px;
}

.pinVideoModal {
  max-width: 500px;
}

.lobbies-menuListing .dropdown-item {
  white-space: break-spaces;
}

.no-wrap {
  white-space: nowrap;
}

.image-picker-game-flow {
  position: relative;

  .custom-file-label {
    display: flex;
    cursor: pointer;
  }

  .custom-file-label::before {
    position: relative;
    margin-right: 10px;
  }
}

.lobbiesProcessingLoader {
  .transaction-spinner {
    left: 63px;
  }

  .processingFile {
    margin-top: 76px;
    padding-left: 10px;
    text-align: center;
  }
}

.tickIconLobbies {
  height: 81px;
  padding-top: 30px;
  width: 30px;
}

.cms-ppk {
  font-weight: 500;
  font-size: 18px;
  color: #000;
  font-style: normal;
}

.add-new-filter img {
  margin-right: 20px;
}

.ppkList {
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}

.faqList {
  margin-bottom: 5px;
}

.faqHeadingList {
  border-bottom: 1px solid #000;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  color: #626c76;
}

.quill-error {
  margin-top: 70px;
}

.permissionsBoxes {
  border: 1px solid;
}

.allowPermissions {
  margin-right: 45px;
}

.premissionsDiv input {
  margin-right: 5px;
  vertical-align: middle;
  height: 19px;
  width: 20px;
}

.allPermissions input {
  margin-right: 5px;
}

.permission {
  border-bottom: 1px solid #000;
  padding: 20px 20px;
}

.permission:last-child {
  border-bottom: unset;
}

.permissionLable,
.premissionsDiv {
  font-size: 16px;
  font-weight: 600;
}

.accordion__button[aria-expanded="true"]::after {
  transform: rotate(-135deg);
}

.cmsEdit {
  color: #fff;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.ppkquestionWidth {
  max-width: 800px;
}

.transaction-icon {
  margin-left: 10px;
  font-size: 20px;
}

.new-filter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  color: #000;
  border-color: transparent;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.filterBy {
  margin-right: 10px;
}

.depositCards {
  margin-right: 30px;
  width: 220px;
  cursor: pointer;

  .card {
    box-shadow: unset;

    .card-header {
      background-color: unset;
      border-color: unset;
      height: 55px;
    }

    .card-text {
      font-size: 24px;
      font-weight: 500;
    }
  }
}

.currentBalance {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #000;

  span {
    font-size: 24px;
  }
}

.reportsViewAll {
  cursor: pointer;
}

.export-csv {
  margin-left: auto;
}

.bg-activeCards {
  color: #fff;
  background-color: #0087a9;
  border-color: #007f9f;
}

.bg-unactiveCards {
  color: #000;
  background-color: #f2f2f2;
  border-color: #007f9f;
}

.react-datepicker-wrapper {
  width: unset;
}

.datepicker-width {
  width: 185px;
}

.report-cards {
  margin-left: 10px;
}

#sidebar-menu .list-unstyled li a {
  img {
    width: 20px;
  }

  span {
    padding-left: 5px;
  }
}

.currentWalletBalance {
  margin-bottom: 20px;
}

.dateFilterIcon {
  background: #f2f2f2;
  border-radius: 8px;
  border: none;
  padding: 8px 16px;
  font-size: 12px;
  // height: 34px;
  min-height: 38px;
  line-height: 24px;
  justify-content: center;
  text-align: center;
  img {
    margin-right: 8px;
    height: 15px;
    margin-top: 3px;
  }
  label {
    // width: 167px;
    margin-bottom: 0;
  }
}

.reports-result-count {
  float: right;
  font-size: 16px;
  font-weight: 600;
}

// .selectedDate {
//   min-width: 146px !important;
// }

.negative-unclaimed-money {
  color: #ed4337 !important;
  font-weight: 600;
}

.date-table {
  width: 125px;
}

.freeEntry {
  width: 71px;
}

.events input.searchEventInput {
  border: 1px solid #000;
  border-radius: 4px;
  padding-left: 10px;
  height: 32px;
}

.ql-snow.ql-toolbar button {
  width: 38px;
}

.whiteCrossIcon {
  width: 18px;
  margin-left: 8px;
  cursor: pointer;
}

.lobby-error-message {
  color: #ff715b;
}

.user-error-message {
  color: #ff715b;
}

tr.highlightRow {
  background-color: antiquewhite;
}

.imageThumb {
  max-height: 75px;
  border: 2px solid;
  padding: 1px;
  cursor: pointer;
}

.pip {
  display: inline-block;
  margin: 10px 10px 0 0;
}

.dropzone {
  border: 2px dashed #ced4da;
  background: #fff;
  border-radius: 6px;
  width: 300px;
  height: 198px;
}
.error-border {
  border: 2px dashed #ff715b !important;
}
// raw editor
.raw-editor {
  display: none;
  width: 100%;
  height: 100px;
}

.showRaw .ql-editor {
  display: none;
}

.showRaw .raw-editor {
  display: block;
}

.text-editor {
  width: 1086px;
  margin: 0 auto;
}

.ql-editor {
  min-height: 100px;
}

.allPermissions .eventsRow {
  width: 100% !important;
}

.viewUploadInput {
  width: 1.15rem;
  height: 0.94rem;
}

span.logoutSpan {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.allPermissions .eventsName {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 17px;
  color: #000;
}

.viewMoreButton {
  background: transparent;
  color: #009fc7 !important;
  font-weight: bold;
  margin: 0 auto;
  border: none;
  font-size: 16px;
  text-decoration: underline;
}

.viewMoreButton:hover,
.viewMoreButton:focus {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.updateProfileCheckbox input {
  width: 19px;
  height: 14px;
}

.react-datepicker__close-icon {
  right: -30px;
}

.sortReferral {
  width: 100px;
}

.valorantDetail {
  overflow-x: auto;
  width: 100%;
}

.lastEnrollmentDate {
  width: 170px;
}

.minutesBox {
  padding: 10px 16px !important;
}

.removePLayer .form-check {
  padding-bottom: 10px;
}

.startTimeBackground {
  background: transparent !important;
}

.lobbyEntryFee {
  margin-bottom: 12px;
}

.validationMessage {
  margin-bottom: 22px;
}

.manageChallengesDelete {
  padding: 3px 10px 3px 10px;
  font-size: 13px;
}

.challnegeDetails {
  text-align: unset !important;
}

.challenge-actions:active {
  color: #fff !important;
}

#transparent-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #000;
  opacity: 0.6;
}

// Safari Browser CSS
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .header-wrapper .dropdown-dropper {
      padding: 5px 45px 0px 12px !important;
    }
  }
}

// Chrome Browser CSS for MAC
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .daysBox .manageDeposit {
    padding: 10px 36px 9.5px;
  }
}

@media (max-width: 1600px) {
  .viewUploadInput {
    width: 1.5rem;
  }
}

@media (max-width: 1320px) {
  .dateFilterIcon {
    padding: 8px 7px;
  }

  .new-filter {
    padding: 8px 7px;
  }
}

@media (max-width: 1300px) {
  .viewUploadInput {
    width: 1.9rem;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .ppkquestionWidth {
    max-width: 600px;
  }
}

@media (min-width: 1200px) and (max-width: 1250px) {
  .terms-conditions-submit-btn,
  .privacy-policy-submit-btn {
    margin-top: 85px !important;
  }

  .add-lobby-btn {
    width: 100%;
  }

  .ppkquestionWidth {
    max-width: 550px;
  }
}

@media (min-width: 1251px) {
  .add-lobby-btn {
    width: 100%;
  }

  .terms-conditions-submit-btn,
  .privacy-policy-submit-btn {
    margin-top: 65px !important;
  }
}

@media (max-width: 1272px) {
  .export-csv {
    margin-left: unset;
    margin-top: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .add-lobby-btn {
    width: 100%;
  }

  .terms-conditions-submit-btn,
  .privacy-policy-submit-btn {
    margin-top: 90px !important;
  }

  .ppkquestionWidth {
    max-width: 450px;
  }
}

@media (min-width: 992px) and (max-width: 1100px) {
  .lobby-results-table .text-center.my-5 {
    max-width: 75%;
  }
}

@media (max-width: 991px) {
  .history-tab {
    width: 100% !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .enrolled-players-tab,
  .lobby-results-tab,
  .lobby-details-view {
    width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
  }

  .ppkquestionWidth {
    max-width: 400px;
  }
}

@media (min-width: 768px) {
  .react-player {
    width: 65% !important;
    height: 65% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .navbar-header {
    padding-left: 26px !important;
    padding-right: 14px !important;
  }

  .add-lobby-btn {
    width: 100%;
  }

  .terms-conditions-submit-btn,
  .privacy-policy-submit-btn {
    margin-top: 60px !important;
  }

  .add-lobby-row {
    justify-content: center;
  }

  .add-staff-member {
    justify-content: center;
  }

  .add-banner-row {
    justify-content: center;
  }

  .ban-location-row {
    justify-content: center;
  }

  .react-player {
    left: 17%;
  }
}

@media (max-width: 767px) {
  .navbar-header {
    padding-left: 24px !important;
    padding-right: 12px !important;
  }

  .terms-conditions-submit-btn,
  .privacy-policy-submit-btn {
    margin-top: 90px !important;
  }

  .add-staff-member {
    justify-content: center;
  }

  .home-tab {
    margin-left: 0px !important;
    width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  // .lobby-details-view {
  //     width: 100%;
  //     padding-left: 0px !important;
  //     padding-right: 0px !important;
  //     margin-left: 0px !important;
  // }
  .enrolled-players-table .text-center.my-5,
  .lobby-results-table .text-center.my-5,
  .seize-wallet-table .text-center.my-5 {
    text-align: left !important;
  }

  .video-player {
    width: 100%;
  }
}

@media (min-width: 641px) {
  .user-view-btn-mb {
    display: none;
  }
}

@media (max-width: 640px) {
  .complaint-view-btn {
    width: auto;
  }

  .users-view-btn {
    display: none;
  }

  .responsiveTable td.view-deactivate-btns {
    padding-left: 16px !important;
  }

  .deactivate-button {
    margin-left: 20px;
  }

  .location-delete-btn,
  .lobbies-view-result-btn {
    padding-top: 0px !important;
  }
}

@media (max-width: 575px) {
  .search-box .users-sort-by-btn {
    margin-top: 16px;
  }

  .add-lobby-btn {
    width: 100%;
  }

  .lobby-fees {
    margin-bottom: 20px;
  }

  .manage-complaints-table td.pivoted {
    padding: 16px 12px;
    line-height: 1.2;
  }
}

@media (max-width: 473px) {
  .terms-conditions-submit-btn,
  .privacy-policy-submit-btn {
    margin-top: 115px !important;
  }
}

@media (min-width: 381px) and (max-width: 390px) {
  .navbar-header {
    padding-left: 16px !important;
    padding-right: 8px !important;
  }
}

@media (max-width: 380px) {
  .navbar-header {
    padding-left: 7px !important;
  }
}

@media (max-width: 373px) {
  .terms-conditions-submit-btn,
  .privacy-policy-submit-btn {
    margin-top: 135px !important;
  }
}
