@import "./icons.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0px;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}
.rangeslider__fill {
  background-color: #556ee6 !important;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  margin: 2em 0;
}
//Crypto enteries
.bs-select select {
  width: auto !important;
}

// .apexcharts-canvas {
//   text {
//     fill: #adb5bd !important;
//   }
// }

.input-group-append,
.input-group-prepend {
  display: flex;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
    column-gap: 24px;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.steps a.nav-link {
  background-color: #c3cef8;
  color: #3b5de7;
}
.steps a.nav-link .number {
  border-color: #3b5de7;
}
.pagination .pagination {
  margin-bottom: 0px;
}

// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}

.search-box {
  margin-bottom: 25px;
  .dropdown-toggle-split {
    line-height: 20px;
  }
}
.search-bar {
  border: 1px solid #5f5f5b80;
  border-radius: 4px;
}
.sidenav-links {
  font-size: 18px !important;
}
.disc-style {
  list-style-type: disc !important;
}
.btn-action {
  margin-left: 16px;
}
.dropdown-action {
  margin-left: 16px;
}
.action-btn-cursor {
  cursor: pointer;
}
.error-msg {
  color: #ff715b;
  font-size: 14px;
  font-style: inherit;
}
.custom-lobbytype-btn {
  position: relative;
  width: 100%;
  top: 5px;
  padding: 7px 0;
  font-size: 14px;
  border-radius: 5px;
}
.flex-align-item-center {
  align-items: center;
}
.disable-cursor {
  cursor: no-drop;
}
.link-color {
  color: #009fc7;
  cursor: pointer;
}
.disabled-link {
  cursor: no-drop;
  width: 100%;
  a {
    cursor: no-drop;
    pointer-events: none;
    color: grey;
  }
}
button {
  a,
  a:hover {
    color: inherit;
  }
}
.hint {
  font-size: 12px;
  color: grey;
  margin: 0;
  padding: 0;
}
.w-706 {
  max-width: 706px;
}
