/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/

.accordion__button {
    color: black;
    cursor: pointer;
    border: none;
    display: inline-block;
    display: flex;
    align-items: center;
    
}


.accordion__button::after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
    transform: rotate(-140deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*----------------- FAQ CSS------------------*/
.faq-cms-page {

 .accordion__button {
    background-color: unset;
    padding: 0;
    color: #000;

    &:before {
        display: none;
    }
    }
}
    