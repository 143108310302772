@mixin md {
    @media only screen and (max-width: 850.9px) {
      @content;
    }
  }
  
  @mixin md-min-max-desktop {
    @media only screen and (min-width: 768px) and (max-width: 1149px) {
      @content;
    }
  }
  
  @mixin md-min-desktop {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
  
  @mixin min-desktop {
    @media only screen and (min-width: 1300px) {
      @content;
    }
  }
  
  @mixin lag-tablet {
    @media only screen and (max-width: 991.9px) {
      @content;
    }
  }
  
  @mixin lag-min-tablet {
    @media only screen and (min-width: 992px) {
      @content;
    }
  }
  
  @mixin max-lag-tablet {
    @media only screen and (max-width: 1023.98px) {
      @content;
    }
  }
  
  @mixin min-lag-tablet {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }
  
  @mixin tablet-landscape {
    @media (min-width: 768px) and (max-width: 991.98px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
      @content;
    }
  }
  
  @mixin min-tablet {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }
  
  @mixin mobile {
    @media only screen and (max-width: 767.9px) {
      @content;
    }
  }
  
  @mixin xsmobile {
    @media only screen and (max-width: 575.98px) {
      @content;
    }
  }
  
  @mixin x-xsmobile {
    @media only screen and (max-width: 474.98px) {
      @content;
    }
  }
  @mixin max-desktop {
    @media only screen and (max-width: 1299px) {
      @content;
    }
  }
  @mixin min-desktop {
    @media only screen and (min-width: 1300px) {
      @content;
    }
  }
  