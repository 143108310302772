.tournament-list {
  > :not(caption) > * > * {
    border-width: 0 1px;
    min-width: 130px;
  }
}
.active-toggle {
  .react-switch-bg {
    background: #009fc7 !important;
  }
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.add-tournament-schedule {
  .accordion__item {
    margin-bottom: 16px;

    .accordion__button {
      justify-content: space-between;
      align-items: center;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      &:before {
        display: none;
      }
    }
  }
  .tournament-round-accordion-heading {
    h5 {
      color: #444;
    }
    .winning-tooltip {
      width: 200px;.admin-code button {
        background-color: unset !important;
    }
      background-color: #c3c3c3;
      text-transform: none;
    }

    .edit-icon {
      background-color: unset !important;
      .edit-tooltip {
        width: auto !important;
        left: 30px;
        background-color: #c3c3c3 !important;
      }
    }
    .editEarning-timeline {
      width: auto;
      .winningPercentage {
        min-width: 230px;
      }
      label {
        color: #444;
        font-weight: 500;
      }
      .info-icon {
        .tooltiptext {
          bottom: 38px;
          right: unset;
          left: 0;
          background-color: #c3c3c3;
        }
      }

      .invalid-feedback {
        font-size: 12px;
      }
    }
  }
}

/******** Add Tournament Schedule *********/
.tournament-filter {
  gap: 16px;

  .dropdown-menu {
    min-width: 200px;
    width: 100% !important;
  }
}

.add-tournament-schedule {
  .accordion__panel {
    .add-schedule {
      th,
      td {
        &:nth-child(4) {
          width: 360px;
        }

        &:last-child {
          width: 150px;
          word-break: break-word;
        }
      }

      tbody {
        .editEarning-timeline {
          width: 100%;
          justify-content: space-between;
          align-items: center;

          .css-2b097c-container {
            min-width: 190px;
          }

          .form-group {
            .mt-4 {
              margin-top: 0 !important;
            }
          }
        }

        .tournamentRound-data {
          align-items: center;
          .edit-icon {
            padding: 0;
          }
        }
      }
    }
  }
}

/*===== Prizes Tournament ===========*/
.prizes {
  gap: 30px;
  .prize-div {
    min-width: 110px;
  }
}

/*===== Add Rank For Team =====*/
.add-tournment-rank {
  .rank {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      font-weight: 500;
    }

    input {
      width: 100px !important;
      text-align: center;
    }
  }
}

.bracket-preview {
  .nav-item {
    button {
      color: #495057;
      font-weight: 500;
    }
  }
}

.admin-code {
  align-items: center;
  button {
    background-color: unset !important;
    padding: 0;
  }
}

.update-admin-button {
  .tooltiptext {
    bottom: 38px  !important;
    right: -5px !important;
  }
}