//
// _helper.scss
//
.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

// Font weight help class
.fw-medium {
    font-weight: $fw-medium;
}

.fw-semibold {
    font-weight: $font-weight-semibold;
}

.username {
    text-align: left;
    padding-left: 24px;
    font-weight: bold;
}

// Social
.social-list-item {
    height: 2rem;
    width: 2rem;
    line-height: calc(2rem - 2px);
    display: block;
    border: 1px solid $gray-500;
    border-radius: 50%;
    color: $gray-500;
    text-align: center;
}

.w-xs {
    min-width: 80px;
}

.w-sm {
    min-width: 95px;
}

.w-md {
    min-width: 110px;
}

.w-lg {
    min-width: 140px;
}

.w-xl {
    min-width: 160px;
}

//small
.small,
small {
    font-size: 80%;
    font-weight: 400;
}

//media
.flex-1 {
    flex: 1;
}