//
// _table.scss
//
.table {
    thead {
        th {
            font-weight: $fw-medium;
            border-top: $table-border-width solid $table-border-color;
        }
    }
    .table-light {
        color: $body-color;
        border-color: $table-border-color;
        th,
        td {
            background-color: $table-head-bg;
        }
    }
}

.table {
    &> :not(:last-child) {
        &> :last-child {
            >* {
                border-bottom-color: $table-border-color;
            }
        }
    }
}

.dataTable {
    td,
    th {
        border: $table-border-width solid $table-border-color !important;
    }
}

//border-bottam
.table> :not(caption)>*>* {
    border-top-width: 1px;
    border-bottom-width: 0px;
}

//dark-border
.table-dark {
    &> :not(:last-child) {
        &> :last-child {
            >* {
                border-bottom-color: $table-dark-border-color;
            }
        }
    }
}

//Table centered
.table-centered {
    td,
    th {
        vertical-align: middle !important;
    }
}

.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}

.home-tab table thead tr td {
    vertical-align: top;
}

.list-of-all-users-table thead tr th,
.lobbies-table thead tr th {
    vertical-align: top;
}
.list-of-all-users-table{
    thead{
        tr{
            th{
                span{
                    cursor: pointer;
                }
            }
        } 
    } 
}
.results-table th {
    vertical-align: top;
}

.table>:not(:first-child) {
    border-top: 2px solid;
}

/**************** Add Tournaments *******************/

.addPrize-tournament {
    thead {
        tr {
            th {
                &:last-child {
                width: 700px;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                &:last-child {
                    width: 700px;
                    }
            }
        }
    }

}

/**************** Modal Css *******************/

.events{
    label{
       .dark{
        color: $black;
       }
        .light{
            color: $gray-light;
        }
    }
    .allpermissions{
        .form-check-input{
            &:focus {
                box-shadow: unset;
            }
        }
    }
}

@media (max-width:767px) {
    .list-of-all-users-table td.pivoted {
        padding: 15px 12px;
    }
}

@media (max-width:640px) {
    .list-of-all-users-table tbody tr,
    .staff-table tbody tr,
    .games-table tbody tr,
    .lobbies-table tbody tr,
    .ban-location-table tbody tr,
    .manage-complaints-table tbody tr {
        border: 1px solid #eff2f7;
    }
}

@media (max-width:575px) {
    .table.lobbies-table> :not(caption)>*>* {
        padding: 18px 12px;
    }
    .lobbies-table td.pivoted {
        line-height: 1.2;
    }
    .list-of-all-users-table .username-field {
        padding-bottom: 24px !important;
    }
    .list-of-all-users-table td.pivoted {
        padding: 16px 12px;
        line-height: 1.2;
    }
}