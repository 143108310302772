.theme-color {
  color: $theme-default-color !important;
}

.min-width-261 {
  min-width: 261px;
}
.w-70 {
  width: 70%;
}

.w-85 {
  width: 85%;
}
.w-540 {
  width: 540px;
}
.w-300 {
  max-width: 300px;
}
.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input[type="date"] {
  text-transform: uppercase;
}

.chip {
  background: $theme-default-color !important;
}

.highlightOption {
  background: $theme-default-color !important;
}
.lobby-game-rules {
  ul {
    margin-bottom: 0;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $theme-default-color;
}

.select2-selection {
  &__control {
    &--is-focused {
      background-color: transparent;
      border-color: $theme-default-color !important;
      outline: 0;
      box-shadow: none;
    }
  }

  &__menu {
    border-radius: 0px;
  }

  &__option {
    &--is-selected {
      background-color: $theme-default-color !important;
      color: #fff;
    }

    &--is-focused {
      background-color: $theme-default-color !important;
      color: #fff !important;
    }
  }

  &__input-container {
    display: flex;
    align-items: center;
    margin: 0;
  }

  &__input {
    opacity: 1 !important;
  }
}

.select2-selection__control {
  &.css-1pahdxg-control {
    box-shadow: unset !important;
    &:hover,
    &:focus {
      border-color: $blue !important;

      box-shadow: unset !important;
    }
  }
}

.select2-selection__menu {
  options {
    background-color: $blue !important;
  }
}

.ql-toolbar.ql-snow {
  border-radius: 0.25rem 0.25rem 0 0;
}
.ql-snow {
  border-radius: 0 0 0.25rem 0.25rem;
}

.searchWrapper {
  padding: 5px 36px 5px 12px;
  position: relative;
  background: transparent;
  z-index: 1;
  cursor: pointer;
}

.multiSelectContainer {
  li {
    &:hover {
      background: $theme-default-color !important;
    }
  }
}

select {
  z-index: 1;
  position: relative;
  background-color: transparent !important;
}

.angleDown {
  position: absolute;
  right: 0;
  top: 36px;
  color: #cccccc;
  border-left: 1px solid #ccc;
  padding: 0 8px;
  &:hover {
    color: hsl(0, 0%, 60%);
  }
  &:focus {
    color: hsl(0, 0%, 20%);
  }
}

.text-danger + .angleDown {
  right: 32px;
}

.css-tj5bde-Svg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}

.tournament-filter {
  .react-datepicker-wrapper {
    min-width: 250px;
    .react-datepicker__close-icon {
      right: 0;
      &:after {
        background-color: $theme-default-color;
      }
    }
  }

  .add-btn {
    margin: auto 0 auto auto;
  }
}

.tournament-list {
  .Action-td {
      min-width: 50px !important;
      vertical-align: top;
        .addSchedule-Admin-icon {
        top: 6px;
      }
    .btn {
      // min-width: 200px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // min-height: 32px;
    }
    .action-td-btn {
      border: 0;
      background: unset !important;
      display: flex;
      align-items: center;
      justify-content: center;
      .tooltiptext {
        width: auto;
        bottom: 22px;
        left: 0;

        &.addTrounamentTip {
          width: 110px !important;
      }
    }
    }
    .action-td-icon {
      width: 16px;
  }
    .tournamnet-delete {
      &.disabled {
        color: #fff !important;
      }
    }
  }
}

.form-control:disabled,
.disabled {
  background-color: #f2f2f2 !important;
  border-color: #ccc !important;
  color: $gray-700 !important;
  opacity: 0.65;
}

.tournament-match-info {
    tr {
      td {
        @include min-tablet {
          width: 25% !important;
        }

        &:nth-child(3) {
          span {
          display: flex;
          gap: 8px;
          flex-direction: column;
          align-items: flex-start;
          width: 100% !important;
          border: 0;
          button {
            padding: 0;
          }
        }
        }
    }
  }
}
.tounamnet-standing-tab {
  td {
    @include min-tablet {
      width: 25% !important;
    }
  }
}
/*======== Date Picker CSS =========*/
.react-datepicker__day {
  &:focus,
  &:hover,
  &:focus-visible {
    border-color: $theme-default-color;
    background-color: $theme-default-color;
    color: #fff;
  }

  &.react-datepicker__day--keyboard-selected {
    background-color: $theme-default-color;
    border-color: $theme-default-color;
    color: #fff;

    &:focus,
    &:hover,
    &:focus-visible {
      border-color: $theme-default-color;
      background-color: $theme-default-color;
    }
  }
}

/*========== CMS Page =========*/
.cmsEdit {
  .tooltiptext {
    right: 0 !important;
  }
}

/*==== activity log popup event ======*/
.allPermissions {
  .d-flex {
    p {
      width: calc(100% - 27px);
    }
  }
}

/*===== FAQ Page =====*/
.faq-cms-page {
  .accordion__button {
    &:hover {
      background-color: unset;
    }
  }
  .accordion__item {
    margin-bottom: 10px;
  }
}

/*======= Bracket Preview =======*/
.round-brackets-preview {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 18px;
    height: 500px;
    overflow: auto;

    .bracket-ctn {
        overflow: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
      }

    .round-comments {
        display: flex;
        align-items: center;
        gap: 100px;
        justify-content: flex-start;

        .round {
           min-width: 240px;
            white-space: normal;
            vertical-align: top;
            background-color: #f4f4f4;
            border: 1px solid #ced4da;
            border-radius: 4px;
            color: #444;
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

             h4 {
                margin-bottom: 0;
                font-size: 18px;
                font-weight: 600;
            }
        }
    }

  .matches {
    margin: 24px 0;
    display: flex;
    align-items: center;
    gap: 100px;
    justify-content: flex-start;

        .round {
           min-width: 240px;
            white-space: normal;
            vertical-align: top;
            border-radius: 4px;
            color: #444;

            .match {
                position: relative;
                margin: 24px 0;
                background-color: #f4f4f4;
                border: 1px solid #ced4da;
                border-radius: 4px;
                padding: 12px;
                cursor: pointer;

                .competitors {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    gap: 2px;

                    span {
                        &:first-child{
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    .no-matches {
      width: auto !important;
      margin: 0 !important;

      .round {
        position: relative;
        top: 14px;
  }
}

}

/*===== Lobby Page =====*/
.valorantDetail {
  .enrolled-players-table {
    .multiselect-container {
      .chip {
        background: transparent !important;
        padding: 0 10px;
      }
    }
  }
}

.ppk-table {
  thead {
    th {
      font-size: 16px;
    }
  }
  td {
    &.winner {
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .win {
      width: 14px;
    }
  }
}

/*===== User List =====*/
.list-of-all-users-table {
  .edit-icon {
    .tooltiptext {
      left: 0;
      right: unset !important;
      bottom: 38px !important;
  }
  }
}