// 
// _demos.scss
// 

// Demo Only
.button-items {
    // margin-left: -8px;
    margin-bottom: -12px;
    
    .btn {
        margin-bottom: 12px;
        margin-left: 8px;
        &:first-child {
          margin-left: 0;
      }
    }

    label {
      font-weight: 600;
    }
    
}

// Lightbox 

.mfp-popup-form {
    max-width: 1140px;
}

// Modals

.bs-example-modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
  }


// Icon demo ( Demo only )
.icon-demo-content {
    text-align: center;
    color: $body-color;
  
    i{
      display: block;
      font-size: 24px;
      margin-bottom: 16px;
      color: $body-color;
      transition: all 0.4s;
    }
  
    .col-lg-4 {
      margin-top: 24px;
  
      &:hover {
        i {
          color: $primary;
          transform: scale(1.5);
        }
      }
    }
}


// Grid

.grid-structure {
    .grid-container {
        background-color: $gray-100;
        margin-top: 10px;
        font-size: .8rem;
        font-weight: $fw-medium;
        padding: 10px 20px;
    }
}

// invoice

.invoice-title{
  .logo-dark{
    display: $display-block !important;
  }

  .logo-light{
    display: $display-none !important;
  }
}