.test {
    width: 30rem;
}

.plus-icon {
    margin-left: 10px;
    font-size: 28px !important;
    padding: 0px 10px 0px 10px !important;
}

.addTeamMatesListing {
    overflow-y: auto;
    max-height: 198px;
}

.addTeamMatesListing {
    .searchTeamList p {
        margin: 0px 0px 0px 0px !important;
        padding: 0px 0px 0px 0px !important;
    }
}

.searchTeamList {
    background: #2e2d2d;
    border: 1px solid #fff;
    padding: 10px;
  }

  .searchTeamList button,
.removeTeamMate button {
  height: 27px;
  padding: 2px 13px 0px 13px !important;
  margin-top: 5px !important;
}

.searchTeamList {
    align-items: baseline;
    cursor: pointer;
  }

.list-group {
    .createList {
        align-items: center;

        .createPlayerName {
            margin-bottom: 0 !important;
        }


    }
}

.createList {
    border-bottom: 1px solid #eee !important;
    border: unset;
  }

  .search-loader{
    display: flex;
    justify-content: center;
}